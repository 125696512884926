
    @import "src/styles/variables.scss";
    $static-assets-url: "https://overseacdn.ydstatic.com/overseacdn/advertising_platform/static";
    
.layout {
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 32px;
  border-bottom: 1px solid #f3f4f6;
  background: #fff;
  z-index: 1000;

  img {
    /* stylelint-disable-next-line */
    image-rendering: -moz-crisp-edges;
    /* Firefox */
    image-rendering: -o-crisp-edges;
    /* Opera */
    /* stylelint-disable-next-line */
    image-rendering: -webkit-optimize-contrast;
    /* Webkit (non-standard naming) */
    -ms-interpolation-mode: nearest-neighbor;
    /* IE (non-standard property) */
  }

  h1 {
    margin: 0;
    font-size: 28px;
  }

  .logo {
    display: flex;
    align-items: center;
    width: auto;
    height: 40px;

    img {
      display: block;
      height: 100%;
    }
  }
}

.container {
  display: flex;
  height: 100vh;
  padding-top: 64px;
}

.new_background {
  background: linear-gradient(106.17deg, #fff8f9 1.5%, #f0fcff 81.06%) !important;
  padding-top: 0;
}

.handler {
  height: 64px;
  gap: 16px;
  :global {
    .ant-space-item {
      height: 100%;
    }
  }
  .dropdown {
    padding: 16px;
    background-color: #fff;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    border-radius: 4px;
  }
  .avatar {
    background-color: $primary-bg-color;
    color: #ff4d15;
    cursor: pointer;
  }
  a {
    color: $primary-color;
  }
  .langSelect {
    display: flex;
    align-items: center;
    height: 100%;
    :global {
      .ant-select {
        width: 100px;
      }
      .ant-select-selector {
        border: none !important;
        &:hover,
        &:focus,
        &:active {
          border: none !important;
        }
      }
    }
  }
}

.sider {
  width: fit-content;
  z-index: 9;
  overflow-y: scroll;
  background: #fff !important;
  user-select: none;
  scrollbar-width: none;
  -ms-overflow-style: none;

  .menu {
    width: 100%;
    border-right: none;
    padding-top: 24px;
    padding-bottom: 40px;
    // 改版
    background: transparent;
    border: none !important;
  }
  :global {
    .ant-menu-inline-collapsed {
      .ant-menu-submenu-title,
      .ant-menu-item {
        svg {
          margin-right: 0;
        }
        span {
          display: none;
        }
      }
    }
    .ant-menu {
      .ant-menu-item,
      .ant-menu-submenu-title {
        transition: none !important;
        &:hover {
          background-color: #f2f4f7 !important;
        }
      }
    }
    .ant-menu-item,
    .ant-menu-submenu {
      color: #6c737f;
    }
    .ant-menu-item-selected {
      color: $primary-text-color;
      background-color: #f2f4f7;
      svg {
        color: #ff3030;
      }
    }
    .ant-menu-submenu-selected {
      .ant-menu-submenu-title {
        color: $primary-text-color;
        svg {
          color: #ff3030;
        }
      }
    }
  }
}

.siderTrigger {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 14px;
  height: 50px;
  line-height: 39px;
  text-align: right;
  border-top: 1px solid #f3f4f6;
  background-color: #fff;
  cursor: pointer;
  z-index: 10;
  width: 260px;
  transition: all 0.2s;

  .siderTriggerIcon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    padding: 8px;
    border-radius: 4px;
    transition: 0.3s all;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  .siderTriggerText {
    margin-left: 10px;
  }
}

.showHeaderChildren {
  max-height: 100vh;
  overflow: auto;
  padding-top: 64px !important;
  background-color: $container-background-color;
}

.menu {
  :global {
    .ant-menu-submenu-selected {
      .ant-menu-submenu-title {
        font-weight: 500;
      }
      .ant-menu-item-selected {
        font-weight: 500;
      }
    }
    .ant-menu-submenu-title {
      display: flex;
      align-items: center;
      svg {
        margin-right: 13px;
      }
    }
    .ant-menu-item {
      display: flex;
      height: fit-content;
      align-items: center;
      white-space: normal;
      line-height: 22px;
      padding: 9px 16px 9px;
      svg {
        margin-right: 13px;
      }
    }
    .ant-menu-submenu .ant-menu-item {
      padding-left: 32px !important;
      &::before {
        content: '';
        margin-right: 20px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #9da4ae;
      }
    }
    .ant-menu-submenu .ant-menu-item-selected {
      &::before {
        width: 6px;
        height: 6px;
        background-color: $primary-color;
      }
    }
    .ant-menu-title-content {
      display: block;
      overflow-wrap: break-word;
    }
  }
}
.notNeedLoginPage {
  position: relative;
  .langSelect {
    position: absolute;
    top: 20px;
    right: 50px;
    :global {
      .ant-select {
        width: 100px;
      }
      .ant-select-selector {
        border: none !important;
        &:hover,
        &:focus,
        &:active {
          border: none !important;
        }
      }
    }
  }
}
@media (min-width: 350px) and (max-width: 554px) {
  .notNeedLoginPage {
    .langSelect {
      display: none;
    }
  }
}
